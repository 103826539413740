import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Enterprises, Footer, WhatsApp } from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Enterprises.module.styl'

interface Context {
  enterprises: Array<never>
  title?: string
  slug?: string
}

const EnterprisesPage = ({ location, pageContext }: PageProps) => {
  const { title, slug } = pageContext as Context
  const { enterprises }: Context = useApi(
    pageContext,
    'page-enterprise' + (slug ? `?search=${slug}` : '')
  )

  return (
    <>
      <Head
        location={location}
        title={`${title ? title + ' - ' : ''}Empreendimentos Aikon`}
      />
      <Enterprises
        title={title || 'Empreendimentos'}
        enterprises={enterprises}
        className={st.enterprisesPage}
        separateSold={!slug}
      />
      <Footer />
      <WhatsApp />
    </>
  )
}

export default EnterprisesPage
